.site-card-wrapper {
    padding: 30px;
    background: #ececec;
    background-image: url(
        '../Images/bgSchoolBook.png'
    );
    background-size: cover;
    height: 100vh;
}

.site-card {
    position: relative;
    top: 17%;
}

@media only screen and (max-width: 720px) {
    .card-container {
      max-width: 100%;
    }

    .card-row {
        max-width: 100%;
    }

    .site-card {
        left: 0;
        max-width: 100%;
    }

    .site-card-wrapper {
        padding-top: 30px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .ant-row.ant-row-center.ant-row-middle.card-row {
        width: 100%;
    }

    .card-container .ant-card-body .ant-form.ant-form-vertical.ant-form-hide-required-mark .ant-row
    .ant-col.ant-col-24.ant-col-offset-4.card-inputs.ant-col-xs-24.ant-col-md-24.ant-col-lg-24 {
        margin-left: 0; 
    }
}

.home__container {
    width: 100%;
    overflow: hidden;
}

@media only screen and (max-width: 576px) {
    .home__container {
      max-width: 540px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .home__container {
      max-width: 720px;
    }
  }
  
  @media only screen and (max-width: 992px) {
    .home__container {
      max-width: 960px;
    }
  }
  
  @media only screen and (max-width: 1200px) {
    .home__container {
      max-width: 1140px;
    }
  }