.box-border {
}

.row-card {
    border: '1px solid';
    transition: transform .1s;
}

.row-card:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.card-image-container {
    background-color: #cff5ff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.card-image {
    width: 50%;
    height: 50%;
}