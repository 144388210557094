.dynamic-delete-button {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
}
.dynamic-delete-button:hover {
    color: #777;
}
.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

.dynamic-add-button {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
}
.dynamic-add-button:hover {
    color: #777;
}
.dynamic-add-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

.blocked {
    pointer-events: none;
    opacity: 0.5;
}

.checkBox {
    display:none;
}

.checkBox {
    background-image: url('../Images/answer_wrong.webp') no-repeat;
    background-size: 100%;
    height: 70px;
    width: 70px;
    padding: 0px;
    display:inline-block;
}

.checkBox:checked {
    background-image: url('../Images/answer_correct.webp') no-repeat;
    background-size: 100%;          
    height: 70px;
    width: 70px;
    padding: 0px;
    display:inline-block;
}

/* h1 {
    text-align: center;
    position: relative;
    color: #fff;
    margin: 0 -30px 30px -30px;
    padding: 10px 0;
    text-shadow: 0 1px rgba(0,0,0,.8);
    background-color: #0274be;
    background-image:  linear-gradient(rgba(255,255,255,.3), rgba(255,255,255,0));
    box-shadow: 0 2px 0 rgba(0,0,0,.3);
  }
  
  h1::before,
  h1::after {
    content: '';
    position: absolute;
    border-style: solid;
    border-color: transparent;
    bottom: -10px;
  }
  
  h1::before {
    border-width: 0 10px 10px 0;
    border-right-color: #222;
    left: 0;
  }
  
  h1::after {
    border-width: 0 0 10px 10px;
    border-left-color: #222;
    right: 0;
  } */