.ant-carousel .slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 100%;
}
.ant-carousel .slick-dots li.slick-active button {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: #1171bb;
}
.ant-carousel .slick-dots li button {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: black;
}