@import url(https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600&display=swap);
body {
  font-family: "Maven Pro", sans-serif;
}

span {
  font-family: "Maven Pro", sans-serif;
}

.ant-table table {
  font-family: "Maven Pro", sans-serif;
}
/* 
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */

.header-solid .ant-card-head {
  border-bottom: 0;
}

.ant-row-flex-middle {
  align-items: center;
}

.font-semibold {
  font-weight: 600;
}

.ant-card.criclebox {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 12px;
}

.ant-card.criclebox .project-ant {
  padding-left: 24px;
  padding-right: 24px;
}

.ant-card.criclebox table th {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.number h3 {
  font-weight: 700;
  margin-bottom: 0px;
  font-size: 30px;
}

.number h3 small {
  font-weight: 600;
  font-size: 14px;
}

.icon-box {
  width: 48px;
  height: 48px;
  text-align: center;
  background: #1890ff;
  color: #fff;
  border-radius: 0.5rem;
  margin-left: auto;
  line-height: 55px;
}

.invoice-list .ant-list-item,
.transactions-list .ant-list-item {
  padding: 11px 0;
  border-bottom: 0;
}
.ant-invoice-card .ant-card-body {
  padding-top: 0px;
}
.invoice-list .ant-list-item-meta-title,
.transactions-list .ant-list-item-meta-title {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
}

.invoice-list .ant-list-item-meta-description,
.transactions-list .ant-list-item-meta-description {
  font-size: 12px;
  font-weight: 600;
  color: #8c8c8c;
}

.invoice-list .ant-list-item-action .ant-btn,
.transactions-list .ant-list-item-action .ant-btn {
  font-weight: 600;
  color: #141414;
  box-shadow: none;
}

.invoice-list .amount,
.transactions-list .amount {
  font-size: 14px;
  font-weight: 600;
  color: #8c8c8c;
}

.transactions-list .amount {
  font-size: 16px;
  font-weight: 700;
}

.mb-24 {
  margin-bottom: 24px;
}

/* 
.ant-btn,
.ant-btn-default,
.ant-dropdown-trigger, 
.ant-dropdown-open {
  background-color: blue;
} */

.ant-table {
  display: block;

  @media screen and (max-width: 600px) {
    &-thead {
      display: none;
    }

    &-thead > tr,
    &-tbody > tr {
      th,
      td {
        &:first-of-type {
          padding-top: 1rem;
        }

        &:last-of-type {
          padding-bottom: 1rem;
        }
      }

      > th,
      > td {
        display: block;
        width: auto !important;
        border: none;
        padding: 0 1rem;
        font-size: 1.1rem;

        &:last-child {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}

/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  color: #fff;
}

body {
  overflow-y: scroll;

}

.trigger:hover {
  color: #1890ff;
}

.logo-container {
  height: 32px;
  width: 32px;
  margin: 16px;
}

.logo {
  height: 100%;
}

.site-layout .site-layout-header {
  background: #1890ff;
}
/* 
.site-layout .site-layout-background {
  background: #fff;
} */

.bread-crumbs {
  background: #fff;
}

.button-right {
  float: right;
  margin-right: 20px;
}

.current-user-container {
  align-self: center;
}

.current-user {
  margin-bottom: 0px;
}

.ant-popover-content {
  border-radius: 2px;
}

.ant-popover-arrow {
  width: 0px !important;
}

.ant-avatar {
  cursor: pointer;
}

.ant-popover-inner {
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgb(60 64 67 / 30%), 0px 2px 6px 2px rgb(60 64 67 / 15%);
  height: 100%;
  width: 100%;
}
/* 
.ant-checkbox-wrapper {
  width: 32px;
  height: 32px;

  justify-content: center;
  align-items: center;

  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.ant-checkbox-wrapper-checked {
  background-color: #1890ff;
  color: #fff;
} */

.my-image:hover {
  cursor: pointer;
}

input[type="checkbox"] {
  background: url(/static/media/answer_wrong.a8e56c99.webp) no-repeat;
}

input[type="checkbox"]:checked +.customcheckbox {
  fill: red;
  background: url(/static/media/answer_correct.543cb55a.webp) no-repeat;
}

/* .ant-checkbox-inner {
  display:none;
}

.ant-checkbox .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  background-size: 100%;
  padding: 0px;
  display:inline-block;
  background:url('../../assets/resources/Images/answer_wrong.webp') no-repeat;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  background-color: gray;
  border-color: gray;
}

.ant-checkbox .ant-checkbox-checked {
  width: 25px;
  height: 25px;
  background-size: 100%;
  padding: 0px;
  display:inline-block;
  background:url('../../assets/resources/Images/answer_correct.webp') no-repeat;
} */

/* .ant-checkbox {
  background:url('../../assets/resources/Images/answer_wrong.webp') no-repeat;
  background-size: 100%;
  height: 32px;
  width: 32px;
  padding: 0px;
  display:inline-block;
}

.ant-checkbox-wrapper .ant-checkbox-wrapper-in-form-item {
  background:url('../../assets/resources/Images/answer_wrong.webp') no-repeat;
  background-size: 100%;
  height: 32px;
  width: 32px;
  padding: 0px;
  display:inline-block;
}

.ant-checkbox .ant-checkbox-checked {
  background:url('../../assets/resources/Images/answer_correct.webp') no-repeat;
  background-size: 100%;          
  height: 32px;
  width: 32px;
  padding: 0px;
  display:inline-block;
}

.ant-checkbox-wrapper .ant-checkbox-wrapper-checked .ant-checkbox-wrapper-in-form-item {
  background:url('../../assets/resources/Images/answer_correct.webp') no-repeat;
  background-size: 100%;          
  height: 32px;
  width: 32px;
  padding: 0px;
  display:inline-block;
}  */

/* .ckbx {
  display:none;
}

.ckbx {
  background:url('https://pixabay.com/static/uploads/photo/2013/07/12/17/40/checkbox-152188_960_720.png') no-repeat;
  background-size: 100%;
  height: 70px;
  width: 70px;
  padding: 0px;
  display:inline-block;
}
.ckbx:checked {
  background:url('https://pixabay.com/static/uploads/photo/2013/07/12/17/40/checkbox-152187_960_720.png') no-repeat;
  background-size: 100%;          
  height: 70px;
  width: 70px;
  padding: 0px;
  display:inline-block;
} */

ul {
  list-style-type: none;
}

li {
  display: inline-block;
}

input[type="checkbox"] {
  display: none;
}

label {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label::before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  -webkit-transform: scale(0);
          transform: scale(0);
}

label div.icon {
  height: 32px;
  width: 32px;
  transition-duration: 0.2s;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

.navbar {
  background-color: white;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 992px) {
  .menu {
    display: none;
  }
}
.logo {
  height: 32px;
  margin-left: 1rem;
}

.ant-drawer-header.layout {
  padding-top: 0px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 0px;
}

.ant-drawer-close {
  position: absolute;
  right: 0;
}
.header-buttons {
  float: right;
  margin-bottom: 10px;
}

.buttons {
  margin-right: 5px;
}

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5715;
}

[data-theme='compact'] .site-description-item-profile-wrapper {
  font-size: 12px;
  line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
}

[data-theme='compact'] .ant-drawer-body p.site-description-item-profile-p {
  font-size: 14px;
  line-height: 1.66667;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}

.ant-checkbox-input {
  z-index: 0 !important;
}
.ant-modal-content {
    border-radius: 15px;
    /* background: red; */
}
.dynamic-delete-button {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
}
.dynamic-delete-button:hover {
    color: #777;
}
.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

.dynamic-add-button {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
}
.dynamic-add-button:hover {
    color: #777;
}
.dynamic-add-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

.blocked {
    pointer-events: none;
    opacity: 0.5;
}

.checkBox {
    display:none;
}

.checkBox {
    background-image: url(/static/media/answer_wrong.a8e56c99.webp) no-repeat;
    background-size: 100%;
    height: 70px;
    width: 70px;
    padding: 0px;
    display:inline-block;
}

.checkBox:checked {
    background-image: url(/static/media/answer_correct.543cb55a.webp) no-repeat;
    background-size: 100%;          
    height: 70px;
    width: 70px;
    padding: 0px;
    display:inline-block;
}

/* h1 {
    text-align: center;
    position: relative;
    color: #fff;
    margin: 0 -30px 30px -30px;
    padding: 10px 0;
    text-shadow: 0 1px rgba(0,0,0,.8);
    background-color: #0274be;
    background-image:  linear-gradient(rgba(255,255,255,.3), rgba(255,255,255,0));
    box-shadow: 0 2px 0 rgba(0,0,0,.3);
  }
  
  h1::before,
  h1::after {
    content: '';
    position: absolute;
    border-style: solid;
    border-color: transparent;
    bottom: -10px;
  }
  
  h1::before {
    border-width: 0 10px 10px 0;
    border-right-color: #222;
    left: 0;
  }
  
  h1::after {
    border-width: 0 0 10px 10px;
    border-left-color: #222;
    right: 0;
  } */
/* .ant-upload.ant-upload-select-picture-card {
    width: 32px;
    height: 32px;
} */
.hangman {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clues {
  display: flex;
  flex-direction: row;
  justify-content: center;
} 

.clue {
  background-color: white !important;
  padding: 16px;
  border-radius: 8px;
}

.direction {
  flex: 1 1;
  padding: 4px;
}

.knwpWu {
  background-color: #FFFF00 !important;
}

.header {
  color: black !important;
  position: inherit !important;
  text-align: start !important;
}

/* @media only screen and (max-width: 600px) {
  .grid {
    width: 100px !important;
    margin: auto !important;
  }
} */

/* @media only screen and (max-width: 959px) {
  .grid {
    width: 600px !important;
    margin: auto !important;
  }
} */

.grid {
  width: 200px  !important;
}
.ant-carousel .slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 100%;
}
.ant-carousel .slick-dots li.slick-active button {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: #1171bb;
}
.ant-carousel .slick-dots li button {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: black;
}
.site-card-wrapper {
    padding: 30px;
    background: #ececec;
    background-image: url(
        /static/media/bgSchoolBook.a02ecd57.png
    );
    background-size: cover;
    height: 100vh;
}

.site-card {
    position: relative;
    top: 17%;
}

@media only screen and (max-width: 720px) {
    .card-container {
      max-width: 100%;
    }

    .card-row {
        max-width: 100%;
    }

    .site-card {
        left: 0;
        max-width: 100%;
    }

    .site-card-wrapper {
        padding-top: 30px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .ant-row.ant-row-center.ant-row-middle.card-row {
        width: 100%;
    }

    .card-container .ant-card-body .ant-form.ant-form-vertical.ant-form-hide-required-mark .ant-row
    .ant-col.ant-col-24.ant-col-offset-4.card-inputs.ant-col-xs-24.ant-col-md-24.ant-col-lg-24 {
        margin-left: 0; 
    }
}

.home__container {
    width: 100%;
    overflow: hidden;
}

@media only screen and (max-width: 576px) {
    .home__container {
      max-width: 540px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .home__container {
      max-width: 720px;
    }
  }
  
  @media only screen and (max-width: 992px) {
    .home__container {
      max-width: 960px;
    }
  }
  
  @media only screen and (max-width: 1200px) {
    .home__container {
      max-width: 1140px;
    }
  }
.row {
    margin: 5px;
}

.col {
    margin-right: 5px;
}

.button {
    margin-right: 5px;
    float: right;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.ant-calendar-picker-input.ant-input {
  width: 100%;
}
.select-dropdown {
    float: right;
}

.row{
    margin: 5px;
}
.steps-content {
    height: 80%;
    margin-top: 16px;
    text-align: center;
}

.steps-action {
    margin-top: 24px;
}
.box-border {
}

.row-card {
    border: '1px solid';
    transition: -webkit-transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s;
}

.row-card:hover {
    cursor: pointer;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.card-image-container {
    background-color: #cff5ff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.card-image {
    width: 50%;
    height: 50%;
}
.table {
    margin-top: 10px;
}
.site-description-item-profile-wrapper {
    margin-bottom: 7px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
  }
  
  [data-theme='compact'] .site-description-item-profile-wrapper {
    font-size: 12px;
    line-height: 1.66667;
  }
  
  .ant-drawer-body p.site-description-item-profile-p {
    display: block;
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 1.5715;
  }
  
  [data-theme='compact'] .ant-drawer-body p.site-description-item-profile-p {
    font-size: 14px;
    line-height: 1.66667;
  }
  
  .site-description-item-profile-p-label {
    display: inline-block;
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.85);
  }
