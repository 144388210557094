@import "~antd/dist/antd.css";

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  color: #fff;
}

body {
  overflow-y: scroll;

}

.trigger:hover {
  color: #1890ff;
}

.logo-container {
  height: 32px;
  width: 32px;
  margin: 16px;
}

.logo {
  height: 100%;
}

.site-layout .site-layout-header {
  background: #1890ff;
}
/* 
.site-layout .site-layout-background {
  background: #fff;
} */

.bread-crumbs {
  background: #fff;
}

.button-right {
  float: right;
  margin-right: 20px;
}

.current-user-container {
  align-self: center;
}

.current-user {
  margin-bottom: 0px;
}

.ant-popover-content {
  border-radius: 2px;
}

.ant-popover-arrow {
  width: 0px !important;
}

.ant-avatar {
  cursor: pointer;
}

.ant-popover-inner {
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgb(60 64 67 / 30%), 0px 2px 6px 2px rgb(60 64 67 / 15%);
  height: 100%;
  width: 100%;
}
/* 
.ant-checkbox-wrapper {
  width: 32px;
  height: 32px;

  justify-content: center;
  align-items: center;

  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.ant-checkbox-wrapper-checked {
  background-color: #1890ff;
  color: #fff;
} */

.my-image:hover {
  cursor: pointer;
}

input[type="checkbox"] {
  background: url('../../assets/resources/Images/answer_wrong.webp') no-repeat;
}

input[type="checkbox"]:checked +.customcheckbox {
  fill: red;
  background: url('../../assets/resources/Images/answer_correct.webp') no-repeat;
}

/* .ant-checkbox-inner {
  display:none;
}

.ant-checkbox .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  background-size: 100%;
  padding: 0px;
  display:inline-block;
  background:url('../../assets/resources/Images/answer_wrong.webp') no-repeat;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  background-color: gray;
  border-color: gray;
}

.ant-checkbox .ant-checkbox-checked {
  width: 25px;
  height: 25px;
  background-size: 100%;
  padding: 0px;
  display:inline-block;
  background:url('../../assets/resources/Images/answer_correct.webp') no-repeat;
} */

/* .ant-checkbox {
  background:url('../../assets/resources/Images/answer_wrong.webp') no-repeat;
  background-size: 100%;
  height: 32px;
  width: 32px;
  padding: 0px;
  display:inline-block;
}

.ant-checkbox-wrapper .ant-checkbox-wrapper-in-form-item {
  background:url('../../assets/resources/Images/answer_wrong.webp') no-repeat;
  background-size: 100%;
  height: 32px;
  width: 32px;
  padding: 0px;
  display:inline-block;
}

.ant-checkbox .ant-checkbox-checked {
  background:url('../../assets/resources/Images/answer_correct.webp') no-repeat;
  background-size: 100%;          
  height: 32px;
  width: 32px;
  padding: 0px;
  display:inline-block;
}

.ant-checkbox-wrapper .ant-checkbox-wrapper-checked .ant-checkbox-wrapper-in-form-item {
  background:url('../../assets/resources/Images/answer_correct.webp') no-repeat;
  background-size: 100%;          
  height: 32px;
  width: 32px;
  padding: 0px;
  display:inline-block;
}  */

/* .ckbx {
  display:none;
}

.ckbx {
  background:url('https://pixabay.com/static/uploads/photo/2013/07/12/17/40/checkbox-152188_960_720.png') no-repeat;
  background-size: 100%;
  height: 70px;
  width: 70px;
  padding: 0px;
  display:inline-block;
}
.ckbx:checked {
  background:url('https://pixabay.com/static/uploads/photo/2013/07/12/17/40/checkbox-152187_960_720.png') no-repeat;
  background-size: 100%;          
  height: 70px;
  width: 70px;
  padding: 0px;
  display:inline-block;
} */

ul {
  list-style-type: none;
}

li {
  display: inline-block;
}

input[type="checkbox"] {
  display: none;
}

label {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label::before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

label div.icon {
  height: 32px;
  width: 32px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

.navbar {
  background-color: white;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 992px) {
  .menu {
    display: none;
  }
}
.logo {
  height: 32px;
  margin-left: 1rem;
}

.ant-drawer-header.layout {
  padding-top: 0px;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 0px;
}

.ant-drawer-close {
  position: absolute;
  right: 0;
}