.hangman {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clues {
  display: flex;
  flex-direction: row;
  justify-content: center;
} 

.clue {
  background-color: white !important;
  padding: 16px;
  border-radius: 8px;
}

.direction {
  flex: 1;
  padding: 4px;
}

.knwpWu {
  background-color: #FFFF00 !important;
}

.header {
  color: black !important;
  position: inherit !important;
  text-align: start !important;
}

/* @media only screen and (max-width: 600px) {
  .grid {
    width: 100px !important;
    margin: auto !important;
  }
} */

/* @media only screen and (max-width: 959px) {
  .grid {
    width: 600px !important;
    margin: auto !important;
  }
} */

.grid {
  width: 200px  !important;
}